import React from 'react';
import Head from 'next/head';
import { ButtonV2 } from '@zola/zola-ui/src/components/Button';
import ZolaLogo from '@zola/zola-ui/src/components/ZolaLogo';
import cx from 'classnames';
import type {
  WProjectCustomizationElementView,
  WProjectCustomizationFullView,
  WProjectCustomizationPageView,
} from '@zola/svc-web-api-ts-client';

import { DigitalCanvas } from '@zola/zola-ui/src/paper/cards/components/DigitalCanvas/DigitalCanvas';

import styles from './SharePreviewGuest.module.less';

const getPreviewWidth = (isXsMobile: boolean, isDesktop: boolean) => {
  if (isXsMobile) return 300;
  if (!isDesktop) return 480;
  return 600;
};

export type SharePreviewGuestProps = {
  animation?: string;
  page: WProjectCustomizationPageView;
  elements: WProjectCustomizationElementView[];
  ownerAndPartnerNames: string;
  backgroundColor?: string;
  opacity?: number;
  showSuperLink?: boolean;
  superLinkSlug: string;
  showWeddingWebsite?: boolean;
  weddingWebsiteSlug: string;
  customizations: WProjectCustomizationFullView[];
  isXsMobile: boolean;
  isDesktop: boolean;
};

const SharePreviewGuest = (props: SharePreviewGuestProps): JSX.Element | null => {
  const {
    animation,
    page,
    elements,
    ownerAndPartnerNames,
    backgroundColor,
    opacity,
    showSuperLink,
    showWeddingWebsite,
    isXsMobile,
    isDesktop,
  } = props;

  const [replayAnimation, setReplayAnimation] = React.useState(false);

  const maxPreviewDimension = getPreviewWidth(isXsMobile, isDesktop);

  const backgroundColorString = backgroundColor && `#${backgroundColor}`;

  const handleSuperLinkClick = () => {
    if (typeof window !== 'undefined') {
      const { superLinkSlug } = props;
      window.open(`/addr/${superLinkSlug}`, '_blank');
    }
  };

  const handleWeddingWebsiteClick = () => {
    if (typeof window !== 'undefined') {
      const { weddingWebsiteSlug } = props;
      window.open(`/wedding/${weddingWebsiteSlug}`, '_blank');
    }
  };

  const title = ownerAndPartnerNames
    ? `${ownerAndPartnerNames}'s Digital Save The Date - Zola`
    : 'Digital Save The Date - Zola';

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="robots" content="noindex" />
        <link
          rel="icon"
          type="image/png"
          href="https://d1tntvpcrzvon2.cloudfront.net/static-assets/images/favicon-rebrand/favicon-32x32.png"
        />
      </Head>
      <div className={styles.previewContainer}>
        <div className={styles.mainContentWrapper}>
          <DigitalCanvas
            animation={animation}
            replayAnimation={replayAnimation}
            setReplayAnimation={setReplayAnimation}
            isGuestExperience
            page={page}
            width={maxPreviewDimension}
            limitHeight
            elements={elements}
            stylesCanvas={styles.previewCanvas}
            backgroundColor={backgroundColorString}
            opacity={opacity}
          />
          <div className={styles.additionalInformationWrapper}>
            <ZolaLogo className={styles.logoContainer} type="logomark" />

            <div>
              <span className={styles.additionalTitle}>
                Save the Date for {ownerAndPartnerNames}&apos;s Wedding!
              </span>

              {showSuperLink && (
                <div className={styles.additionalInformation}>
                  <p className={styles.additionalText}>
                    <span className={cx(styles.additionalSubtitle, styles.mobileCapitalize)}>
                      Come celebrate!
                      <span role="img" aria-label="party popper">
                        🎉
                      </span>
                    </span>
                    Share your address to receive an invitation.
                  </p>
                  <div>
                    <ButtonV2
                      onClick={() => handleSuperLinkClick()}
                      className={styles.additionalButton}
                    >
                      Give your info
                    </ButtonV2>
                  </div>
                </div>
              )}

              {showWeddingWebsite && (
                <div className={styles.additionalInformation}>
                  <p className={styles.additionalText}>
                    <span className={styles.additionalSubtitle}>
                      For details, check out {ownerAndPartnerNames}&apos;s wedding website.
                    </span>
                  </p>
                  <div>
                    <ButtonV2
                      onClick={() => handleWeddingWebsiteClick()}
                      className={styles.additionalButton}
                    >
                      Visit wedding website
                    </ButtonV2>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SharePreviewGuest;
